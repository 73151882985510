import { DiscordSocialWalletConnector } from "../../../connectors/DiscordSocialWalletConnector.js";
export function discordWallet({ chains, options }) {
    return {
        iconBackground: '#fff',
        iconUrl: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/91_Discord_logo_logos-512.png',
        id: 'openlogin_discord',
        name: 'Discord',
        createConnector: function () {
            return { connector: new DiscordSocialWalletConnector({ chains, options }) };
        }
    };
}
