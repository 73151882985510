import { ZeroDevConnector } from "./ZeroDevConnector.js";
import { ZeroDevWeb3Auth } from '@zerodev/web3auth';
import { getConfig } from '@wagmi/core';
import { connect } from 'wagmi/actions';
import { getRPCProviderOwner } from '@zerodev/sdk';
export class AbstractWeb3AuthWalletConnector extends ZeroDevConnector {
    constructor({ chains = [], options }) {
        super({ chains, options });
        this.getChainId().then(chainId => {
            if (this.options.projectIds) {
                const web3AuthInitOptions = {};
                this.web3Auth = new ZeroDevWeb3Auth(this.options.projectIds, chainId, {
                    adapterSettings: options.adapterSettings,
                    web3authOptions: options.web3authOptions
                });
                if (getConfig().storage?.getItem(`${this.loginProvider}-connecting`)
                    ||
                        (options.shimDisconnect && getConfig().storage?.getItem(this.shimDisconnectKey))) {
                    web3AuthInitOptions['onConnect'] = async (userInfo) => {
                        const typeOfLogin = userInfo.typeOfLogin;
                        if (this.loginProvider === typeOfLogin || (this.loginProvider === 'auth0' && typeOfLogin === 'jwt')) {
                            if (this.web3Auth?.provider) {
                                this.owner = getRPCProviderOwner(this.web3Auth.provider);
                            }
                            connect(({ chainId, connector: this })).catch(() => { });
                        }
                        getConfig().storage?.setItem(`${this.loginProvider}-connecting`, false);
                    };
                }
                this.web3Auth.initialize(web3AuthInitOptions, this.loginProvider);
            }
        }).catch(() => { });
    }
    async isAuthorized() {
        return false;
    }
    async connect({ chainId }) {
        if (!this.owner) {
            let provider = this.web3Auth?.provider;
            if (this.web3Auth?.status === 'connected') {
                const typeOfLogin = (await this.web3Auth?.getUserInfo())?.typeOfLogin;
                if (typeOfLogin !== this.loginProvider && (this.loginProvider !== 'auth0' || typeOfLogin !== 'jwt')) {
                    await this.web3Auth?.logout();
                    provider = null;
                }
            }
            if (!this.web3Auth?.connected) {
                getConfig().storage?.setItem(`${this.loginProvider}-connecting`, true);
                provider = await this.web3Auth?.login(this.loginProvider);
                setTimeout(() => {
                    getConfig().storage?.setItem(`${this.loginProvider}-connecting`, false);
                }, 1000);
            }
            if (provider) {
                this.owner = getRPCProviderOwner(provider);
            }
        }
        return await super.connect({ chainId });
    }
    async getOptions() {
        if (this.owner) {
            this.options.owner = this.owner;
        }
        const options = await super.getOptions();
        options.disconnect = async () => {
            await this.web3Auth?.logout();
            this.owner = undefined;
        };
        return options;
    }
}
