import { useNetwork, useQuery, useWalletClient } from 'wagmi';
import { prepareSendUserOperation } from '../core/prepareSendUserOperation.js';
function queryKey({ account, activeChainId, chainId, data, to, value, scopeKey, walletClientAddress, }) {
    return [
        {
            entity: 'prepareSendUserOperation',
            activeChainId,
            account,
            chainId,
            data,
            to,
            value,
            scopeKey,
            walletClientAddress,
        },
    ];
}
function queryFn({ walletClient }) {
    return ({ queryKey: [{ account, chainId, data, to, value, },], }) => {
        if (!to)
            throw new Error('to is required');
        return prepareSendUserOperation({
            account,
            chainId,
            data,
            to,
            value,
            walletClient,
        });
    };
}
export function usePrepareSendUserOperation({ account, chainId, cacheTime, data, enabled = true, scopeKey, staleTime, suspense, to, value, onError, onSettled, onSuccess, } = {}) {
    const { chain: activeChain } = useNetwork();
    const { data: walletClient } = useWalletClient({ chainId });
    const prepareSendUserOperationQuery = useQuery(queryKey({
        activeChainId: activeChain?.id,
        account,
        chainId,
        data,
        scopeKey,
        to,
        value,
        walletClientAddress: walletClient?.account.address,
    }), queryFn({ walletClient }), {
        cacheTime,
        enabled: Boolean(enabled && walletClient && to),
        staleTime,
        suspense,
        onError,
        onSettled,
        onSuccess,
    });
    return Object.assign(prepareSendUserOperationQuery, {
        config: {
            mode: 'prepared',
            ...(prepareSendUserOperationQuery.isSuccess
                ? prepareSendUserOperationQuery.data
                : undefined),
        },
    });
}
