import { FacebookSocialWalletConnector } from "../../../connectors/FacebookSocialWalletConnector.js";
export function facebookWallet({ chains, options }) {
    return {
        iconBackground: '#fff',
        iconUrl: 'https://cdn2.iconfinder.com/data/icons/social-icons-33/128/Facebook-512.png',
        id: 'openlogin_facebook',
        name: 'Facebook',
        createConnector: function () {
            return { connector: new FacebookSocialWalletConnector({ chains, options }) };
        }
    };
}
