import { GithubSocialWalletConnector } from "../../../connectors/GithubSocialWalletConnector.js";
export function githubWallet({ chains, options }) {
    return {
        iconBackground: '#fff',
        iconUrl: 'https://cdn4.iconfinder.com/data/icons/logos-and-brands/512/142_Github_logo_logos-512.png',
        id: 'openlogin_github',
        name: 'Github',
        createConnector: function () {
            return { connector: new GithubSocialWalletConnector({ chains, options }) };
        }
    };
}
