import { TwitchSocialWalletConnector } from "../../../connectors/TwitchSocialWalletConnector.js";
export function twitchWallet({ chains, options }) {
    return {
        iconBackground: '#fff',
        iconUrl: 'https://cdn4.iconfinder.com/data/icons/logos-brands-7/512/twitch-1024.png',
        id: 'openlogin_twitch',
        name: 'Twitch',
        createConnector: function () {
            return { connector: new TwitchSocialWalletConnector({ chains, options }) };
        }
    };
}
