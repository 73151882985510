import { enhanceConnectorWithAA } from '../../utilities/enhanceConnectorWithAA.js';
export const enhanceWalletWithAAConnector = (wallet, params) => {
    return new Proxy(wallet, {
        get(target, prop, receiver) {
            const source = Reflect.get(target, prop, receiver);
            if (prop === "createConnector") {
                return () => {
                    const result = source();
                    return {
                        ...result,
                        connector: enhanceConnectorWithAA(result["connector"], params)
                    };
                };
            }
            return source;
        }
    });
};
