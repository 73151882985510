import { getChainConfig } from './chainConfig.js';
import { ZERODEV_CLIENT_ID } from '../constants.js';
export const getWeb3AuthConfig = (chainId, web3authOptions) => {
    return {
        uiConfig: {
            defaultLanguage: "en",
            primaryButton: 'socialLogin'
        },
        chainConfig: chainId ? getChainConfig(chainId) : {
            chainNamespace: 'eip155',
        },
        clientId: ZERODEV_CLIENT_ID,
        enableLogging: false,
        ...(web3authOptions ?? {})
    };
};
