import { getNetwork } from 'wagmi/actions';
import { ChainMismatchError, ChainNotConfiguredError } from '@wagmi/core';
export function assertActiveChain({ chainId, signer, }) {
    const { chain: activeChain, chains } = getNetwork();
    const activeChainId = activeChain?.id;
    if (activeChainId && chainId !== activeChainId) {
        throw new ChainMismatchError({
            activeChain: chains.find((x) => x.id === activeChainId)?.name ??
                `Chain ${activeChainId}`,
            targetChain: chains.find((x) => x.id === chainId)?.name ?? `Chain ${chainId}`,
        });
    }
    if (signer) {
        const signerChainId = signer.provider
            ?.network?.chainId;
        if (signerChainId && chainId !== signerChainId) {
            throw new ChainNotConfiguredError({
                chainId,
                connectorId: 'unknown',
            });
        }
    }
}
