import { BACKEND_URL } from "./constants.js";
export function isMobileDevice() {
    if (typeof window === 'undefined' || !window.navigator)
        return false;
    const userAgent = navigator.userAgent;
    const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
    return mobileRegex.test(userAgent);
}
const projectConfigurationCache = {};
export const getProjectsConfiguration = async (projectIds, backendUrl) => {
    const projectIdsKey = projectIds.join('-');
    if (projectConfigurationCache[projectIdsKey] === undefined) {
        projectConfigurationCache[projectIdsKey] = new Promise((resolve, reject) => {
            fetch(`${backendUrl ?? BACKEND_URL}/v1/projects/get`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    projectIds: projectIds.map(projectId => projectId.toString())
                })
            }).then(resp => {
                resp.json().then(resolve).catch(reject);
            }).catch(reject);
        });
    }
    return await projectConfigurationCache[projectIdsKey];
};
