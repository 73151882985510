import { ConnectorNotFoundError, getWalletClient } from '@wagmi/core';
import { assertActiveChain } from '../utilities/assertActiveChain.js';
import { prepareSendUserOperation } from './prepareSendUserOperation.js';
export async function sendUserOperation({ account, chainId, data, mode, to, value, }) {
    const walletClient = await getWalletClient({ chainId });
    if (!walletClient)
        throw new ConnectorNotFoundError();
    if (chainId)
        assertActiveChain({ chainId });
    let args;
    if (mode === 'prepared') {
        args = {
            account,
            chain: null,
            data,
            to: to,
            value,
        };
    }
    else {
        args = await prepareSendUserOperation({
            account,
            chainId,
            data,
            to,
            value,
        });
    }
    const isArray = Array.isArray(args.to) || Array.isArray(args.data) || Array.isArray(args.value);
    if (isArray) {
        const isValidTo = Array.isArray(args.to);
        const isValidData = args.data === undefined || (Array.isArray(args.data) && args.data.length === args.to.length);
        const isValidValue = args.value === undefined || (Array.isArray(args.value) && args.value.length === args.to.length);
        if (!isValidTo || !isValidData || !isValidValue) {
            throw new Error("If one parameter is an array, then all parameters need to be an array.");
        }
    }
    const { hash } = await walletClient.sendUserOperation(isArray ? args.to.map((target, index) => ({
        target,
        data: args.data && Array.isArray(args.data) ? args.data[index] : undefined,
        value: args.value && Array.isArray(args.value) ? args.value[index] : undefined
    })) : {
        target: args.to,
        data: args.data,
        value: args.value
    });
    return { hash: await walletClient.waitForUserOperationTransaction(hash) };
}
