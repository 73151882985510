import { useMemo } from "react";
import { encodeFunctionData, parseAbi } from "viem";
import { usePrepareSendUserOperation } from "./usePrepareSendUserOperation.js";
import { isStringArray } from "../utilities/isStringArray.js";
export const usePrepareContractBatchWrite = (config) => {
    const to = useMemo(() => {
        if (!config.enabled)
            return [];
        return config.calls.map(call => call.address);
    }, [config?.calls]);
    const data = useMemo(() => {
        if (!config.enabled)
            return [];
        return config.calls.map(call => encodeFunctionData({ abi: isStringArray(call.abi) ? parseAbi(call.abi) : call.abi, functionName: call.functionName, args: call.args }));
    }, [config?.calls]);
    const value = useMemo(() => {
        if (!config.enabled)
            return [];
        return config.calls.map(call => call.value);
    }, [config?.calls]);
    return usePrepareSendUserOperation({
        ...config,
        to,
        data,
        value
    });
};
