import { TwitterSocialWalletConnector } from "../../../connectors/TwitterSocialWalletConnector.js";
export function twitterWallet({ chains, options }) {
    return {
        iconBackground: '#fff',
        iconUrl: 'https://cdn3.iconfinder.com/data/icons/inficons/512/twitter.png',
        id: 'openlogin_twitter',
        name: 'Twitter',
        createConnector: function () {
            return { connector: new TwitterSocialWalletConnector({ chains, options }) };
        }
    };
}
