import { isAddress } from 'viem';
import { ConnectorNotFoundError, fetchEnsAddress, getWalletClient } from '@wagmi/core';
import { assertActiveChain } from '../utilities/assertActiveChain.js';
const resolveAddress = async (to) => {
    return (to && !isAddress(to)
        ? await fetchEnsAddress({ name: to })
        : to) || undefined;
};
export async function prepareSendUserOperation({ account, chainId, data, to: to_, value, walletClient: walletClient_, }) {
    const walletClient = walletClient_ ?? (await getWalletClient({ chainId }));
    if (!walletClient)
        throw new ConnectorNotFoundError();
    if (chainId)
        assertActiveChain({ chainId });
    let to;
    if (Array.isArray(to_)) {
        to = [];
        let index = 0;
        for (let t of to_) {
            const target = await resolveAddress(t);
            if (target && !isAddress(target))
                throw new Error('Invalid address');
            to[index] = target;
            index++;
        }
    }
    else {
        const target = await resolveAddress(to_);
        if (target && !isAddress(target))
            throw new Error('Invalid address');
        to = target;
    }
    return {
        account,
        data,
        mode: 'prepared',
        to: to,
        value,
        ...(chainId ? { chainId } : {}),
    };
}
