class ZeroDevApiService {
    static async getProjectConfiguration(projectId) {
        const resp = await fetch(`${ZeroDevApiService.baseUrl}/v1/projects/${projectId}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });
        return await resp.json();
    }
}
ZeroDevApiService.baseUrl = process.env.REACT_APP_ZERODEV_BACKEND_URL ?? 'https://backend-vikp.onrender.com';
export { ZeroDevApiService };
