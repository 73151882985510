import * as React from 'react';
import { useMutation } from 'wagmi';
import { sendUserOperation } from '../core/sendUserOperation.js';
export const mutationKey = (args) => [{ entity: 'sendUserOperation', ...args }];
const mutationFn = ({ account, chainId, data, mode, to, value, }) => {
    if (!to)
        throw new Error('to is required.');
    return sendUserOperation({
        account,
        chainId,
        data,
        mode,
        to,
        value,
    });
};
export function useSendUserOperation({ account, chainId, data: data_, mode, to, value, onError, onMutate, onSettled, onSuccess, } = {}) {
    const { data, error, isError, isIdle, isLoading, isSuccess, mutate, mutateAsync, reset, status, variables, } = useMutation(mutationKey({
        account,
        chainId,
        data: data_,
        mode,
        to,
        value,
    }), mutationFn, {
        onError,
        onMutate,
        onSettled,
        onSuccess,
    });
    const sendUserOperation = React.useCallback((args) => mutate({
        chainId,
        mode,
        ...(args || {
            account,
            chainId,
            data: data_,
            mode,
            value,
            to,
        }),
    }), [
        account,
        chainId,
        data_,
        mode,
        mutate,
        to,
        value,
    ]);
    const sendUserOperationAsync = React.useCallback((args) => mutateAsync({
        chainId,
        mode,
        ...(args || {
            account,
            chainId,
            data: data_,
            mode,
            value,
            to,
        }),
    }), [
        account,
        chainId,
        data_,
        mode,
        mutateAsync,
        to,
        value,
    ]);
    return {
        data,
        error,
        isError,
        isIdle,
        isLoading,
        isSuccess,
        reset,
        sendUserOperation: (mode === 'prepared' && !to
            ? undefined
            : sendUserOperation),
        sendUserOperationAsync: (mode === 'prepared' && !to
            ? undefined
            : sendUserOperationAsync),
        status,
        variables,
    };
}
